document.addEventListener("turbolinks:load", () => {
  // 帳務首頁搜尋列的子科根據選取母科變化
  var grade_select
  var sub_grade_selection
  if (document.getElementById("q_grade_id_eq") != null) {
    grade_select = document.getElementById("q_grade_id_eq")
    sub_grade_selection = document.getElementById("q_sub_grade_id_eq").options
  } else if (document.getElementById("accounting_grade_id") != null) {
    grade_select = document.getElementById("accounting_grade_id")
    sub_grade_selection = document.getElementById("accounting_sub_grade_id").options
  } else {
    return
  }


  for (i = sub_grade_selection.length - 1; -1 < i; i--) {
    if (sub_grade_selection[i].dataset.grade == grade_select.value) {
      sub_grade_selection[i].hidden = false
    } else {
      sub_grade_selection[i].hidden = true
    }
  }

  grade_select.addEventListener('change', (event) => {
    for (i = sub_grade_selection.length - 1; -1 < i; i--) {
      if (sub_grade_selection[i].dataset.grade == grade_select.value) {
        sub_grade_selection[i].hidden = false
        sub_grade_selection.selectedIndex = i
      } else {
        sub_grade_selection[i].hidden = true
      }
    }
  });
})
