document.addEventListener("turbolinks:load", () => {
  var ctx = document.getElementById('amountChart');
  if (ctx != null) {
    var amountChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: JSON.parse(ctx.dataset.label),
        datasets: [{
          label: '當月餘額',
          data: JSON.parse(ctx.dataset.total),
          backgroundColor: [
            'rgba(255, 206, 86, 0.2)'
          ],
          borderColor: [
            'rgba(255, 206, 86, 1)'
          ],
          borderWidth: 1,
          fill: 'origin',
          type: 'bar',
          order: 0
        },
        {
          label: '當月收入',
          data: JSON.parse(ctx.dataset.income),
          backgroundColor: [
            'rgba(54, 162, 235, 0.2)'
          ],
          borderColor: [
            'rgba(54, 162, 235, 1)'
          ],
          borderWidth: 1,
          fill: 'origin'
        },
        {
          label: '當月支出',
          data: JSON.parse(ctx.dataset.cost),
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)'
          ],
          borderWidth: 1,
          fill: 'origin'
        }]
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            max: 24
          },
          y: {
            display: true,
            beginAtZero: true,
          }
        },
        plugins: {
          title: {
            display: true,
            text: "帳本餘額表",
            font: {
              size: 30
            },
            padding: {
              top: 20,
              bottom: 30
            }
          }
        }
      }
    });
  }
})
